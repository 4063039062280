<script setup>
</script>

<template>
  <main class="page">
    <PageMainAiAssistant />
    <slot />
    <LayoutBottomNav />
  </main>
</template>

<style scoped lang="scss">
.page {
  padding-bottom: 69px;
}
</style>
